/* .menuLink:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}
.menuLink::before {
  content: "";
  display: block;
  height: 23px;
}
.menuLink::after {
  content: "";
  width: 100%;
  height: 27px;
  margin-bottom: -5px;
  display: block;

  border-bottom: 4px solid purple;
  opacity: 0.3;
  transform: scale(0, 1);
  transition-timing-function: ease-out;
  transition-property: all;
  transition-delay: 0s;
  transition-duration: 0.5s;
  pointer-events: none;
}
.menuLinkActive::after {
  content: "";
  display: block;
  margin-bottom: -5px;
  height: 27px;
  border-bottom: 4px solid;
}
.menuLinkActive::before {
  content: "";
  display: block;
  height: 23px;
} */

.link {
font-size: 17px;
padding: 0px 13px;
}
 .separators{
  border-right: 1px solid rgb(115, 115, 115);
  
}

.topbar{
  background-color: rgb(0, 0, 0);
}

@media only screen and (max-width: 600px) {
  .separators {
    border-right: 0px solid rgb(115, 115, 115);
  }
}
.pointer{
  cursor: pointer;
}


.menuLink{
  background-color: transparent;
  color: black;
}
.menuLink:hover{
  background-color: rgb(231, 231, 231);
  color: green;

}
.menuLinkActive{
  background-color: rgb(231, 231, 231);
  color: green;

}