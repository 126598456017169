

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-70%, 0, 0);
    transform: translate3d(-70%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-70%, 0, 0);
    transform: translate3d(-70%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.h-100vh{
  height: 100vh;
}
.inputSearch{
  background-image: url('./assets/search.svg');
  background-position: 3px 5px;
  background-repeat: no-repeat;
  background-size: 28px;
  padding: 8px 10px 8px 34px !important;
  cursor: pointer;
}
.aboutbg{
  background-image: url('./assets/banner1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  width: 100%;
}

.newslatterbg{
  border-radius: 10px;
  background-image: linear-gradient(to right, rgb(187 22 85), rgb(201 97 135), rgb(93 54 235) , rgb(54 160 235),rgb(0, 140, 255))
}
.ReactFlagsSelect-module_selectBtn__19wW7{
  border: none!important;
}
/* .ReactFlagsSelect-module_selectFlag__2q5gC>svg{
  border-radius: 50%;
} */

.position-md-relative{
  position: relative;
}
.h-500px{
  height: 500px;
}
.h-418px{
  height: 418px;
}
.h-200px{
  height: 200px;
}
.f-56px{
  font-size: 56px;
}
@media screen and (max-width: 768px) {
  .h-500px{
    height: 200px;
  }
.f-56px{
font-size: 14px;
}
.aboutbg{
  height: 200px;
}
  .h-418px{
    height: 200px;
  }
  .position-md-relative{
    position: static;
  }
}


select.form-select:focus, 
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
button.btn:focus, 
button.btn:active, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
    outline: none !important;
}

.image-list {
  display: grid;
  grid-gap: 10px;
}
.rsuite-menu>div{
  padding: 5px 10px!important;
}

.tnr{
  font-family: "Times New Roman", Times, serif
}
.rs-table-header-row-wrapper{
  z-index: 0!important;
}

